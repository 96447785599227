export const SVGChartLineUp = () => (
  <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.2465 12.7563H11.8975C10.6354 12.7563 9.42491 13.2577 8.53243 14.1502C7.63994 15.0427 7.13855 16.2532 7.13855 17.5153V60.3463C7.13855 61.6085 7.63994 62.819 8.53243 63.7114C9.42491 64.6039 10.6354 65.1053 11.8975 65.1053H64.2465C65.5087 65.1053 66.7191 64.6039 67.6116 63.7114C68.5041 62.819 69.0055 61.6085 69.0055 60.3463V17.5153C69.0055 16.2532 68.5041 15.0427 67.6116 14.1502C66.7191 13.2577 65.5087 12.7563 64.2465 12.7563ZM59.4875 57.9668H16.6565C16.0255 57.9668 15.4202 57.7161 14.974 57.2699C14.5277 56.8236 14.277 56.2184 14.277 55.5873V22.2743C14.277 21.6433 14.5277 21.038 14.974 20.5918C15.4202 20.1455 16.0255 19.8948 16.6565 19.8948C17.2876 19.8948 17.8929 20.1455 18.3391 20.5918C18.7853 21.038 19.036 21.6433 19.036 22.2743V45.0848L29.25 34.8678C29.471 34.6466 29.7335 34.4711 30.0223 34.3513C30.3112 34.2316 30.6208 34.17 30.9335 34.17C31.2462 34.17 31.5559 34.2316 31.8447 34.3513C32.1336 34.4711 32.396 34.6466 32.617 34.8678L38.072 40.3258L51.3645 27.0333H45.2105C44.5794 27.0333 43.9742 26.7826 43.528 26.3364C43.0817 25.8902 42.831 25.2849 42.831 24.6538C42.831 24.0228 43.0817 23.4175 43.528 22.9713C43.9742 22.525 44.5794 22.2743 45.2105 22.2743H57.108C57.7391 22.2743 58.3443 22.525 58.7906 22.9713C59.2368 23.4175 59.4875 24.0228 59.4875 24.6538V36.5513C59.4875 37.1824 59.2368 37.7876 58.7906 38.2339C58.3443 38.6801 57.7391 38.9308 57.108 38.9308C56.4769 38.9308 55.8717 38.6801 55.4255 38.2339C54.9792 37.7876 54.7285 37.1824 54.7285 36.5513V30.3974L39.7555 45.3733C39.5345 45.5946 39.2721 45.7701 38.9832 45.8898C38.6944 46.0096 38.3847 46.0712 38.072 46.0712C37.7593 46.0712 37.4497 46.0096 37.1608 45.8898C36.872 45.7701 36.6095 45.5946 36.3885 45.3733L30.9335 39.9153L19.036 51.8128V53.2078H59.4875C60.1186 53.2078 60.7238 53.4585 61.1701 53.9048C61.6163 54.351 61.867 54.9562 61.867 55.5873C61.867 56.2184 61.6163 56.8236 61.1701 57.2699C60.7238 57.7161 60.1186 57.9668 59.4875 57.9668Z"
      fill="url(#paint0_linear_47_7115)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_47_7115"
        x1="128.389"
        y1="-4.36369"
        x2="31.1123"
        y2="145.085"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.196063" stopColor="#FE9224" />
        <stop offset="1" stopColor="#FFE1BD" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGChartLineUp;
