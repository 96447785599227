import { StaticImage } from 'gatsby-plugin-image';
import Files from 'images/smsf/Files';
import Gauge from 'images/smsf/Gauge';
import ChartLineUp from 'images/smsf/ChartLineUp';
import { BlogLink, FlexibleLinkComponent, HelpLink, PartnerLink } from 'components/Links';
import type { Translations } from 'helpers/mergeTranslations';
import type { BlogPost } from 'types/blog/blogPost';

export const getSmsfCards = ({ trackedInvestments }: Translations) => [
  {
    cardIdentifier: 'track-1',
    title: 'Track all your SMSF asset types',
    text: `Track price and performance data on over ${trackedInvestments} global shares, ETFs and managed funds, plus unlisted assets such as property.`,
    image: <ChartLineUp />,
  },
  {
    cardIdentifier: 'track-2',
    title: 'More than just SMSF tracking',
    text: 'Track your performance and calculate tax implications across both your personal and SMSF investment portfolios.',
    image: <Gauge />,
  },
  {
    cardIdentifier: 'track-3',
    title: 'Run tax reports for SMSFs',
    text: 'Run reports built for SMSF trustees including asset allocation, investment income, franking credits, and capital gains tax.',
    image: <Files />,
  },
];

export const blogPosts: BlogPost[] = [
  {
    id: 'how-one-couple-saves-time-and-money-using-sharesight',
    title: 'How one couple saves time and money using Sharesight',
    urlSlug: '/blog/how-one-couple-saves-time-and-money-using-sharesight/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'How one couple saves time and money using Sharesight',
      staticImage: (
        <StaticImage
          src="../images/smsf/save-time-money.png"
          alt="How one couple saves time and money using Sharesight banner"
        />
      ),
    },
    categories: [{ name: 'Product features', urlSlug: '/blog/sharesight-features-tips' }],
    author: 'Prashant Mohan',
    authorTitle: 'Chief Marketing Officer',
    authorCompany: 'Sharesight',
    description:
      'Over time Nick and Katie’s spreadsheets became increasingly difficult to maintain, and they realised it had become untenable to keep going this way.',
    date: new Date('2020-06-19'),
  },

  {
    id: 'how-smsf-trustees-can-get-eofy-ready',
    title: 'How SMSF trustees can get EOFY-ready',
    urlSlug: '/blog/how-smsf-trustees-can-get-eofy-ready/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'ETFs vs. stocks',
      staticImage: (
        <StaticImage
          src="../images/smsf/eofy-ready.jpeg"
          alt="How SMSF trustees can get EOFY-ready banner"
        />
      ),
    },
    categories: [{ name: 'Investing tips', urlSlug: '/blog/investing-tips' }],
    author: 'Stephanie Stefanovic',
    authorTitle: 'Content Manager',
    authorCompany: 'Sharesight',
    description:
      'Keep reading to learn more about how Sharesight saves SMSF trustees time and money at tax time, and how trustees can get EOFY-ready with Sharesight. ',
    date: new Date('2024-06-05'),
  },

  {
    id: 'the-importance-of-asset-allocation-tracking-for-smsfs',
    title: 'The importance of asset allocation tracking for SMSFs',
    urlSlug: '/blog/the-importance-of-asset-allocation-tracking-for-smsfs/',
    content: '',
    image: {
      gatsbyImageData: null,
      title: 'The importance of asset allocation tracking for SMSFs',
      staticImage: (
        <StaticImage
          src="../images/smsf/asset.png"
          alt="The importance of asset allocation tracking for SMSFs banner"
        />
      ),
    },
    categories: [{ name: 'Product features', urlSlug: '/blog/sharesight-features-tips' }],
    author: 'Stephanie Stefanovic',
    authorTitle: 'Content Manager',
    authorCompany: 'Sharesight',
    description:
      'Like all investments, it’s critical to track the performance of and distributions earned by exchange-traded funds (ETFs) in your portfolio.',
    date: new Date('2021-02-04'),
  },
];

// TODO: add links
export const smsfFAQ = [
  {
    question: 'Who is Sharesight’s SMSF portfolio management software suitable for?',
    answer: (
      <p>
        Sharesight’s online portfolio tracker is designed for the needs of self-directed investors,
        including self-managed superannuation fund (SMSF) trustees. With Sharesight, investors can
        effortlessly track all of their investments in one place, including investments across asset
        classes, markets, currencies, share registries and brokers. This makes it easy for SMSF
        trustees to track their personal investments in the same place they track their SMSF, and
        gives trustees the data they need to prepare annual returns and file SMSF tax paperwork.
      </p>
    ),
  },
  {
    question: 'How do I track SMSF investments with Sharesight?',
    answer: (
      <p>
        It’s easy to track your SMSF investments in Sharesight, once you have signed up for an
        account and created an SMSF portfolio, you can simply import your investments via our{' '}
        <BlogLink to="automatically-import-trades-to-your-sharesight-portfolio-using-email">
          Trade Confirmation Email
        </BlogLink>{' '}
        feature,{' '}
        <BlogLink to="import-trades-with-the-sharesight-file-importer">
          spreadsheet file importer
        </BlogLink>{' '}
        or manually enter your trades. Once your SMSF investments have been added to your portfolio,
        your performance will automatically be tracked, including all dividends and distributions,
        franking credits and currency fluctuations.
      </p>
    ),
  },
  {
    question: 'SMSF admin & reporting features in Sharesight:',
    answer: (
      <ul>
        <li>
          <HelpLink to="changing-the-tax-setting-of-your-portfolio">
            Tax settings for SMSFs
          </HelpLink>
          : Sharesight automatically applies the correct CGT discount rate for SMSFs at the
          portfolio level.
        </li>
        <li>
          <BlogLink to="5-ways-to-get-the-most-out-of-sharesights-taxable-income-report">
            Taxable Income Report
          </BlogLink>
          : Determine your SMSF’s taxable income with a breakdown of any dividends, distributions,
          franking credits and interest payments you earned throughout the financial year.
        </li>
        <li>
          <BlogLink to="capital-gains-tax-calculator-for-australian-investors">
            Capital Gains Tax Report
          </BlogLink>
          : Automatically calculate capital gains for your SMSF tax return according to the ATO’s{' '}
          <FlexibleLinkComponent to="https://www.ato.gov.au/individuals-and-families/super-for-individuals-and-families/self-managed-super-funds-smsf/in-detail/smsf-resources/smsf-checklists">
            SMSF tax rules
          </FlexibleLinkComponent>
          .
        </li>
        <li>
          <BlogLink to="tax-loss-selling-for-australian-investors">
            Unrealised Capital Gains Tax Report
          </BlogLink>
          : View all of your SMSF portfolio’s unrealised capital gains to explore various tax loss
          selling scenarios and make informed investment decisions.
        </li>
        <li>
          <BlogLink to="view-and-export-your-trading-history-with-the-all-trades-report">
            All Trades Report
          </BlogLink>
          : Verify your records by viewing a list of all buy and sell trades made in your SMSF
          portfolio over any selected period.{' '}
        </li>
        <li>
          <BlogLink to="prepare-your-annual-accounts-with-sharesights-historical-cost-report">
            Historical Cost Report
          </BlogLink>
          : Prepare your SMSF’s{' '}
          <FlexibleLinkComponent to="https://www.ato.gov.au/individuals-and-families/super-for-individuals-and-families/self-managed-super-funds-smsf/administering-and-reporting/lodge-smsf-annual-returns">
            annual reporting
          </FlexibleLinkComponent>{' '}
          with the ability to compare the market value and historical cost of your SMSF portfolio
          over any selected period.
        </li>
        <li>
          <BlogLink to="calculate-investment-portfolio-diversification-sharesight">
            Diversity Report
          </BlogLink>
          : Determine the diversification and asset allocation of your SMSF portfolio to help
          evaluate your{' '}
          <FlexibleLinkComponent to="https://www.ato.gov.au/individuals-and-families/super-for-individuals-and-families/self-managed-super-funds-smsf/investing/your-investment-strategy">
            SMSF investing strategy
          </FlexibleLinkComponent>
          , rebalance your portfolio to meet your SMSF’s diversification requirements and prepare
          your annual reports.
        </li>
        <li>
          <BlogLink to="how-to-track-monthly-dividend-income-from-your-investments">
            Future Income Report
          </BlogLink>
          : Project your SMSF’s cash flow by tracking upcoming announced dividends.
        </li>
        <li>
          <PartnerLink to="partners/xero">Xero integration</PartnerLink>: Connect your Sharesight
          and Xero accounts to sync details of your trades and dividend payments to Xero, plus
          reconcile transactions against your SMSF bank account, including superannuation
          contributions.
        </li>
      </ul>
    ),
  },
  {
    question: 'Does Sharesight’s SMSF tool help with Australian tax reporting?',
    answer: (
      <p>
        Sharesight’s online portfolio tracker is a cost-effective platform designed to help
        investors prepare their Australian tax return and meet their{' '}
        <FlexibleLinkComponent to="ato.gov.au/individuals-and-families/super-for-individuals-and-families/self-managed-super-funds-smsf/administering-and-reporting/record-keeping-requirements">
          SMSF reporting requirements
        </FlexibleLinkComponent>
        . Tax reporting tools such as the{' '}
        <BlogLink to="5-ways-to-get-the-most-out-of-sharesights-taxable-income-report">
          Taxable Income Report
        </BlogLink>{' '}
        and{' '}
        <BlogLink to="capital-gains-tax-calculator-for-australian-investors">
          Capital Gains Tax Report
        </BlogLink>{' '}
        are useful for investors who need an SMSF software that will give them a breakdown of all
        the dividends/distributions, franking credits, interest payments and capital gains they have
        incurred in their SMSF portfolio. SMSF trustees can also download these reports to share
        with their accountant, or even securely{' '}
        <BlogLink to="save-time-money-by-sharing-your-portfolio-with-your-accountant">
          share access
        </BlogLink>{' '}
        to their Sharesight portfolio - so your accountant sees the exact same thing you do.
      </p>
    ),
  },
  {
    question:
      'How to track net wealth position and investment performance across SMSF and personal portfolios?',
    answer: (
      <p>
        With Sharesight, you can track everything from stocks and ETFs to managed funds,{' '}
        <BlogLink to="track-cash-accounts-in-sharesight">brokerage cash accounts</BlogLink>,{' '}
        <BlogLink to="how-to-track-an-investment-property-in-sharesight">property</BlogLink> and{' '}
        <BlogLink to="track-bitcoin-and-other-cryptocurrencies-with-sharesight">
          cryptocurrency
        </BlogLink>{' '}
        – all in one place. This means you can clearly see the full picture of your performance (and
        your net wealth). Simply separate your personal investments and SMSF investments into
        different portfolios, making it easier to track the performance of your portfolios
        individually (as well as the tax implications). It’s also easy to see your wealth and
        performance across multiple Sharesight portfolios by creating a{' '}
        <HelpLink to="consolidated_views">Consolidated View</HelpLink>, which amalgamates the
        stocks, ETFs and funds across all of your portfolios into one “view”, which you can access
        in your Portfolio Overview page, as well as throughout the majority of Sharesight reports.
      </p>
    ),
  },
];
