export const SVGFiles = () => (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M64.7389 20.4384L52.7425 8.44202C52.5195 8.21929 52.2548 8.04267 51.9635 7.92225C51.6723 7.80184 51.3601 7.73999 51.045 7.74024H27.0522C25.7795 7.74024 24.559 8.2458 23.6591 9.1457C22.7592 10.0456 22.2536 11.2661 22.2536 12.5388V17.3374H17.4551C16.1824 17.3374 14.9619 17.8429 14.062 18.7428C13.1621 19.6427 12.6565 20.8633 12.6565 22.1359V65.323C12.6565 66.5956 13.1621 67.8162 14.062 68.7161C14.9619 69.616 16.1824 70.1215 17.4551 70.1215H51.045C52.3176 70.1215 53.5382 69.616 54.4381 68.7161C55.338 67.8162 55.8435 66.5956 55.8435 65.323V60.5244H60.6421C61.9148 60.5244 63.1353 60.0188 64.0352 59.1189C64.9351 58.219 65.4407 56.9985 65.4407 55.7258V22.1359C65.4409 21.8207 65.3791 21.5086 65.2586 21.2174C65.1382 20.9261 64.9616 20.6614 64.7389 20.4384ZM41.4479 58.1251H27.0522C26.4158 58.1251 25.8056 57.8723 25.3556 57.4224C24.9057 56.9724 24.6529 56.3622 24.6529 55.7258C24.6529 55.0895 24.9057 54.4792 25.3556 54.0293C25.8056 53.5793 26.4158 53.3266 27.0522 53.3266H41.4479C42.0842 53.3266 42.6945 53.5793 43.1444 54.0293C43.5944 54.4792 43.8471 55.0895 43.8471 55.7258C43.8471 56.3622 43.5944 56.9724 43.1444 57.4224C42.6945 57.8723 42.0842 58.1251 41.4479 58.1251ZM41.4479 48.528H27.0522C26.4158 48.528 25.8056 48.2752 25.3556 47.8253C24.9057 47.3753 24.6529 46.7651 24.6529 46.1287C24.6529 45.4924 24.9057 44.8821 25.3556 44.4322C25.8056 43.9822 26.4158 43.7294 27.0522 43.7294H41.4479C42.0842 43.7294 42.6945 43.9822 43.1444 44.4322C43.5944 44.8821 43.8471 45.4924 43.8471 46.1287C43.8471 46.7651 43.5944 47.3753 43.1444 47.8253C42.6945 48.2752 42.0842 48.528 41.4479 48.528ZM60.6421 55.7258H55.8435V31.733C55.8438 31.4179 55.7819 31.1057 55.6615 30.8145C55.5411 30.5232 55.3645 30.2585 55.1418 30.0355L43.1453 18.0391C42.9224 17.8164 42.6577 17.6398 42.3664 17.5194C42.0752 17.399 41.763 17.3371 41.4479 17.3374H27.0522V12.5388H50.0523L60.6421 23.1286V55.7258Z"
      fill="url(#paint0_linear_47_7114)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_47_7114"
        x1="80.5869"
        y1="24.8047"
        x2="0.444154"
        y2="104.947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEA93B" />
        <stop offset="1" stopColor="#FF8A41" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGFiles;
