import { useMemo, type FC } from 'react';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { BlogLink, GatsbyLink, SignupLink } from 'components/Links';
import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { Cards } from 'components/Cards';
import { StatsBlock } from 'components/StatsBlock';
import { PricingBlock } from 'components/PricingBlock';
import { TrustPilot } from 'components/TrustPilot';
import { Accordion } from 'components/Accordion';
import { BlogPreviewCards } from 'components/PreviewCards';
import { SMSFNotice } from 'components/Notice';

import { blogPosts, getSmsfCards, smsfFAQ } from 'page-data/smsf';

import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const SmsfPage: FC<LocalizedPageProps> = () => {
  const {
    translations: { builtForInvestors, investorsJustLikeYou, trackedInvestments },
  } = useContentfulLocale();

  const { cards } = useContentfulPage();

  const localisedTaxCards = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('tax-alt-')),
    [cards]
  );

  const smsfCards = getSmsfCards({ trackedInvestments });

  return (
    <Layout hideFooterCta>
      <Seo />

      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>SMSF portfolio tracking software</h1>
          <p>
            Simplify your self managed super fund administration with an award-winning online SMSF
            portfolio tracker and reporting software built for Australian investors.
          </p>
          <br />

          <SignupLink asButton />
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'smsf/hero',
            alt: "Graphic illustration of Sharesight's overview page UI on a laptop with stock, bitcoin and other graph symbols flowing in to the computer.",
          })}
          boxShadow={false}
        />
      </HeroContainer>
      <Cards cards={smsfCards} />
      <Container>
        <Section>
          <h2>“How I manage my SMSF with Sharesight”</h2>
          <p>Find out how self-directed investor Andrew Bird uses Sharesight to manage his SMSF.</p>
          <br />
          <BlogLink to="how-i-manage-my-smsf-with-sharesight" asButton />
        </Section>
        <SectionVideo videoId="ar0gI5ew7pE" />
      </Container>
      <SMSFNotice />
      <Container columns={4}>
        <SectionImage
          span={2}
          image={useGatsbyImage({
            name: 'smsf/reports',
            alt: "Illustration of Sharesight's performance and tax reports overview page UI.",
          })}
          boxShadow={false}
        />
        <Section span={2}>
          <h2>Everything you need to track your SMSF and personal investments</h2>
          <p>
            With Sharesight, all your investment data lives in one place. Track both your SMSF and
            personal investment portfolios and access powerful reports that make it easy to
            calculate both your investment{' '}
            <GatsbyLink to="investment-portfolio-performance">performance</GatsbyLink> and{' '}
            <GatsbyLink to="investment-portfolio-tax">tax</GatsbyLink>.
          </p>
        </Section>
      </Container>
      <Container>
        <Section>
          <h2>Understand your SMSF performance</h2>
          <p>
            Dive deeper into the performance of your SMSF asset allocation strategy using
            Sharesight’s{' '}
            <BlogLink to="explore-your-portfolio-performance-with-the-contribution-analysis-report">
              Contribution Analysis Report
            </BlogLink>
            , which shows you the contribution of each asset class to the overall performance of
            your portfolio.
          </p>
          <p>
            Group the investments in your portfolio along the dimensions of your choice to compare
            performance across different countries, industries, sectors and more.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'smsf/contribution',
            alt: "Illustration of Sharesight's contribution analysis report.",
          })}
          boxShadow={false}
        />
      </Container>
      <Container>
        <SectionImage
          image={useGatsbyImage({
            name: 'smsf/capital-gains',
            alt: "Illustration of Sharesight's capital gains tax report.",
          })}
          boxShadow={false}
        />
        <Section>
          <h2>Calculate your capital gains tax</h2>
          <p>
            Calculate your capital gains on investments as per Australian Tax Office (ATO) rules
            with the{' '}
            <BlogLink to="capital-gains-tax-calculator-for-australian-investors">
              Capital Gains Tax Report
            </BlogLink>
            . You can also optimise your tax position by comparing sales allocation methods and
            parcel cost bases.
          </p>
        </Section>
      </Container>
      <SMSFNotice />
      <Container>
        <Section>
          <h2>Swap your spreadsheet for Sharesight</h2>
          <p>
            Managing your SMSF portfolio used to involve manually updating a spreadsheet and hours
            spent with your accountant. Online SMSF portfolio management tools like Sharesight make
            it easy.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'smsf/swap',
            alt: 'Graphic illustrating swapping out spreadsheet for Sharesight SMSF portfolio tracking software.',
          })}
          boxShadow={false}
        />
      </Container>
      <Cards
        cards={localisedTaxCards}
        header={builtForInvestors}
        subHeader={investorsJustLikeYou}
      />
      <Container>
        <Section>
          <BlogPreviewCards entries={blogPosts} />
        </Section>
      </Container>
      <Container columns={4}>
        <SectionImage
          span={2}
          image={useGatsbyImage({
            name: 'smsf/acc',
            alt: 'Illustration of SMSF portfolio tracker UI for accountants.',
          })}
          boxShadow={false}
        />
        <Section span={2}>
          <h2>Are you an accountant?</h2>
          <p>
            Sharesight’s professional SMSF accounting software is the perfect fit for you and your
            clients. Find out more about the professional offering on our{' '}
            <GatsbyLink to="accountants">accountants page</GatsbyLink>.
          </p>
        </Section>
      </Container>
      <StatsBlock />
      <PricingBlock />
      <TrustPilot tagId="smsf" localizeTag={false} />
      <Accordion title="SMSF Portfolio Tracker FAQs" details={smsfFAQ} injectFAQStructuredData />
      <SMSFNotice />
    </Layout>
  );
};

export default SmsfPage;
